import React, { useEffect, useState } from 'react';
import { Menu, RefinementList, useClearRefinements, usePagination, useRefinementList } from 'react-instantsearch';

import { companyFilter, professionalFilter } from '../../util/helper';
import { Button, OutsideClickHandler } from '../../components';

import css from './AlgoliaSearchPage.module.css';


const AlgoliaFilterPanel = ({ searchState, Panel, isMoreFilters, roleName }) => {
  const [popup, setPopup] = useState(false);
  useRefinementList({ attribute: 'proSoftwareTools.label' });
  useRefinementList({ attribute: 'specifyDiscipline' });
  useRefinementList({ attribute: 'budgetLabel' });
  useRefinementList({ attribute: 'proSoftwareToolsSubCat.label' });
  useRefinementList({ attribute: 'requiredPros' });
  useRefinementList({ attribute: 'idealTimeframe' });
  useRefinementList({ attribute: 'spokenLanguages' });
  useRefinementList({ attribute: 'spokenLanguages.label' });
  useRefinementList({ attribute: 'disciplines.label' });
  const { refine, canRefine } = useClearRefinements();
  const { refine: setPage } = usePagination();
  useEffect(() => {
    if (isMoreFilters) {
      setFilterredPanels(filterPanels);
    } else {
      setFilterredPanels(filterPanels.slice(0, 5));
    }
  }, [isMoreFilters]);

  const filterPanels = roleName === process.env.REACT_APP_ALGOLIA_COMPANY_LISTING_INDEX ? professionalFilter : companyFilter
  const [filterredPanels, setFilterredPanels] = useState(filterPanels.slice(0, 5));

  const clearHandler = () => {
    refine()
    if (typeof window != undefined) {
      window.history.pushState("", "", '/s')
    }
  }

  return (
    <>
      {filterredPanels.map((item, index) => (
        <div key={item.title + index} className={css.categoryWrapper}>
          <div>
            <button
              className={css.category}
              onClick={() => setPopup(popup == item.title ? '' : item.title)}
            >
              {item.title}
            </button>
          </div>
          {popup == item.title && (
            <OutsideClickHandler onOutsideClick={() => { popup && setPopup('') }} className={css.popupWrapper}>
              <Panel>
                {item.component === 'RefinementList' && (
                  <RefinementList
                    attribute={item.attribute}
                    searchablePlaceholder={item.searchablePlaceholder}
                    showMore={false}
                    sortBy={['name:desc', 'count:asc']}
                  />
                )}
                {item.component === 'Menu' && <Menu attribute={item.attribute} showMore={true} />}
              </Panel>
            </OutsideClickHandler>
          )}
        </div>
      ))}
      <div className={css.clearBtn}>
        <Button disabled={!canRefine} onClick={() => { clearHandler() }}>
          Clear Filters
        </Button>
      </div >
    </>
  );
};

export default AlgoliaFilterPanel;
